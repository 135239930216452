<template>
    <div>
        <div class="car-and-video">
            <div class="car-and-video-main">
                <!--AD banner-->
                <div class="banner">
                    <a :href="$store.state.webConf.banner_ad[0].url"
                       v-if="$store.state.webConf.banner_ad && $store.state.webConf.banner_ad.length > 0">
                        <el-image
                                style="width: 100%; height: 100%"
                                :src="$store.state.webConf.banner_ad[0].img"
                                fit="fill"
                        >
                        </el-image>
                    </a>
                </div>
                <!--视频-->
                <div class="video">
                    <h2 class="m-center">卡片战斗先导者的频道</h2>
                    <el-divider></el-divider>
                    <div class="video-item">
                        <div >
                            <div class="video-banner">
                                <span>{{$webConfig.bilibili1.title}}</span>
                                <a target="_blank" :href="$webConfig.bilibili1.url">跳转查看</a>
                            </div>
                            <div class="index-video-item" v-html="$store.state.webConf.show_video1"></div>
                        </div>
                        <div >
                            <div class="video-banner">
                                <span>{{$webConfig.bilibili2.title}}</span>
                                <a target="_blank" :href="$webConfig.bilibili2.url">跳转查看</a>
                            </div>
                            <div class="index-video-item" v-html="$store.state.webConf.show_video2"></div>
                        </div>
                    </div>
                </div>
                <!--每日一卡-->
                <div class="day-card">
                    <h1>每日一卡</h1>
                    <el-divider></el-divider>
                    <div class="card-img">
                        <router-link to="/index/dayCardDetail" v-if="selectData.length > 0">
                            <el-image
                                    style="height: 100%;width: 100%"
                                    :src="switchImg"
                                    fit="fill"
                            >
                            </el-image>
                        </router-link>
                    </div>
                    <router-link to="" v-if="selectData.length == 0">
                        <el-image
                                style="height: 100%;width: 80%"
                                src="https://bushiroad.oss-cn-beijing.aliyuncs.com/index/EsTlDGCBCSi5kbzoZVuIklszJkddprK31653981140.jpg"
                                fit="fill"
                        >
                        </el-image>
                    </router-link>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "card-video",
        data() {
            return {
                selectData: [],
                switchImg:"https://bushiroad.oss-cn-beijing.aliyuncs.com/index/EsTlDGCBCSi5kbzoZVuIklszJkddprK31653981140.jpg",
            }
        },
        methods: {
            getData(){
                this.$axios.post("site/siteDayCards/getDayCards",this.form)
                    .then((res)=>{
                        if (res.data.code == 1000){
                            this.selectData = res.data.data.selectData;
                            let max = this.selectData.length;
                            if (max > 0){
                                this.switchImg = this.selectData[0].img;
                                var index = 1;
                                setInterval(()=>{
                                    if (index >= max){
                                        index = 0;
                                    }
                                    this.switchImg = this.selectData[index].img;
                                    console.log(this.switchImg);
                                    index ++
                                },5000)
                            }
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    })
            }
        },
        created() {
            this.getData();

        }
    }
</script>

<style scoped>
    .car-and-video{
        width: 100%;
        padding-top: 20px;
    }
    .car-and-video-main{
        width: 98%;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 20px;
    }
    .day-card{
        width: 100%;
    }
    .day-card h1{
        font-weight: bold;
        font-size: 20px;
        text-align: center;
    }
    .card-img{
        width: 68%;
        margin: 0 auto;
        min-height: 440px;
    }
    .video-item{
        width: 100%;
    }
    .video-item > div{
        width: 98%;
        /*position: relative;*/
    }
    .video-banner{
        /*position: absolute;*/
        height: 40px;
        background-color: black;
        width: 99.9%;
        opacity: 0.8;
        color: white;
        font-weight: bold;
        line-height: 40px;
    }
    .video-banner >a {
        text-decoration: none;
        color: white;
        border: 1px solid white;
        float: right;
        display: block;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        padding: 0 5px;
        margin-top: 5px;
        margin-right: 2%;

    }
    .video-banner >span{
        margin-left: 5%;
    }
</style>
